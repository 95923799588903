import { render, staticRenderFns } from "./ShowTypeActions.vue?vue&type=template&id=47f206f6&scoped=true&"
import script from "./ShowTypeActions.vue?vue&type=script&lang=js&"
export * from "./ShowTypeActions.vue?vue&type=script&lang=js&"
import style0 from "./ShowTypeActions.vue?vue&type=style&index=0&id=47f206f6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f206f6",
  null
  
)

export default component.exports